<!--
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-16 17:17:52
 * @LastEditTime: 2020-05-07 18:15:25
 -->
<template>
  <div class="invoice-form">
    <div class="fp-tip">
      <div class="tip-word">
        <div class="tip-icon">
          <i class="jeicon jeicon-volume-up" />
        </div>
        <div class="words">
          <div>温馨提示：</div>
          <div>1、订单完成90天内，可支持向客服或销售人员申请开具增值税发票。</div>
          <div>2、发票申请提交后如有内容更新，可及时联系客服或销售人员进行变更。</div>
        </div>
      </div>
    </div>

    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="180px"
      class="invoice-ruleForm"
      size="small"
    >
      <el-form-item
        label="发票类型:"
        prop="type"
      >
        <el-select
          v-model="ruleForm.invoiceCode"
          placeholder="请选择发票类型"
        >
          <el-option
            label="6%增值税专用票"
            value="6%增值税专用票"
          />
          <el-option
            label="6%增值税普通票"
            value="6%增值税普通票"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="发票抬头:"
        prop="invoiceTitle"
      >
        <el-input
          v-model="ruleForm.invoiceTitle"
          type="text"
        />
      </el-form-item>
      <el-form-item
        label="税务登记证号:"
        prop="registryNumber"
      >
        <el-input v-model="ruleForm.registryNumber" />
      </el-form-item>
      <el-form-item
        label="开户银行名称:"
        prop="bankName"
      >
        <el-input v-model="ruleForm.bankName" />
      </el-form-item>
      <el-form-item
        label="基本开户账号:"
        prop="accountNumber"
      >
        <el-input v-model="ruleForm.accountNumber" />
      </el-form-item>
      <el-form-item
        label="注册场所地址:"
        prop="address"
      >
        <el-input v-model="ruleForm.address" />
      </el-form-item>
      <el-form-item
        label="注册固定电话:"
        prop="telePhone"
      >
        <el-input v-model.number="ruleForm.telePhone" />
      </el-form-item>
      <el-divider />
      <el-form-item
        label="收件人:"
        prop="recipients"
      >
        <el-input
          v-model="ruleForm.recipients"
          class="sjr"
        />
      </el-form-item>
      <el-form-item
        label="手机号:"
        prop="phone"
      >
        <el-input
          v-model.number="ruleForm.phone"
          class="sjr"
          type="phone"
        />
      </el-form-item>
      <el-form-item
        label="地址:"
        prop="registeredAddress"
      >
        <el-input v-model="ruleForm.registeredAddress" />
      </el-form-item>
      <el-form-item class="submitBtn">
        <el-button
          size="medium"
          type="danger"
          @click="doSubmit"
        >
          提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { POST_INVOICE_ADDINVOICE } from '@/pages/index/actions/api';
import rules from '@/util/rulesUtil';

export default {
  data() {
    return {
      invoiceAmount: '',
      ids: '',
      ruleForm: {
        ids: '', // 订单ids
        address: '', // 地址
        bankName: '', // 开户银行名称
        invoiceCode: '', // 发票类型
        invoiceName: '', // 发票类型name
        invoiceTitle: '', // 发票抬头
        phone: '', // 手机号
        recipients: '', // 收件人
        registeredAddress: '', // 收件人地址
        registryNumber: '', // 税务注册登记号
        telePhone: '', // 固定电话
        accountNumber: '', // 基本开户账号
        invoiceAmount: '', // 发票金额
      },
      rules: {
        phone: [{ required: true, validator: rules.validatePhone, trigger: 'blur' }],
        invoiceTitle: [{ required: true, message: '请输入发票抬头', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入开户银行名称', trigger: 'blur' }],
        recipients: [{ required: true, message: '请输入收件人', trigger: 'blur' }],
        registeredAddress: [{ required: true, message: '请输入注册场所地址', trigger: 'blur' }],
        registryNumber: [{ required: true, message: '请输入税务登记证号', trigger: 'blur' }],
        telePhone: [{ required: true, message: '请输入注册固定电话', trigger: 'blur' }],
        accountNumber: [{ required: true, message: '请输入基本开户账号', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      // 刷新页面时数据丢失 返回列表重选
      const initData = this.$route.params;
      if (!initData.moneyAll) {
        this.$message({
          message: '已选数据丢失，请重新选择！',
          type: 'warning',
        });
        this.$router.back(-1);
      } else {
        this.invoiceAmount = initData.moneyAll;
        this.ids = initData.initFormData;
      }
    },
    submit(data) {
      data.invoiceAmount = this.invoiceAmount;
      data.ids = this.ids;
      this.$ajax({
        url: POST_INVOICE_ADDINVOICE,
        data: { ...data },
      }).then((order) => {
        if (!order.data.success) {
          return this.$message({
            message: '提交申请失败！',
            type: 'error',
          });
        }
        this.$message({
          message: '提交申请成功！',
          type: 'success',
        });
        this.$router.push({
          path: '/order/invoice/indexInvoice',
        });
      });
    },
    doSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.ruleForm);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less">
.invoice-form {
  .fp-tip {
    height: 117px;
    background-color: #fff;
    border-radius: 6px;
    padding: 8px;
    box-sizing: border-box;
    margin: 20px 0;
    .tip-word {
      display: flex;
      background-color: #fff2f0;
      border-radius: 6px;
      height: 100%;
      .tip-icon {
        padding-left: 10px;
        i {
          font-size: 18px;
          color: #b31818;
        }
      }
      .words {
        flex: 1;
        padding: 7px;
        font-size: 14px;
        color: #3f3f3f;
      }
    }
  }
  .invoice-ruleForm {
    background-color: #fff;
    border-radius: 6px;
    padding: 40px 24px;
    .el-form-item__label {
      font-size: 16px;
      color: #3f3f3f;
      padding-right: 40px;
    }
    .el-form-item--small.el-form-item {
      margin-bottom: 30px;
    }
    .el-divider--horizontal {
      margin: 60px 0;
    }
    .submitBtn {
      text-align: center;
      .el-form-item__content {
        margin-left: 0 !important;
        .el-button {
          width: 500px;
          background: rgba(246, 71, 78, 1);
          border-radius: 8px;
          color: #fff;
          &:hover {
            background: rgba(246, 71, 78, 0.8);
          }
        }
      }
    }
    .el-input {
      width: 430px;
    }
    .el-select {
      .el-input {
        width: 240px;
      }
    }
    .sjr {
      width: 240px;
    }
  }
}
</style>
